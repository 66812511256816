import { useMemo, useState } from 'react'
import { Currency, Pair, Token, Percent, CurrencyAmount } from '@gemlaunch/sdk'
import {
  Button,
  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  NumericalInput,
  CopyButton,
  InputProps,
  ArrowDropDownIcon,
} from '@gemlaunch/uikit'
import styled, { css, useTheme } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@gemlaunch/localization'
import { WrappedTokenInfo } from '@gemlaunch/token-lists'

import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { formatNumber } from '@gemlaunch/utils/formatBalance'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useAccount } from 'wagmi'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  /* padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')}; */
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  padding: 0 0.5rem;
  /* ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      padding: 8px;
      background: ${theme.colors.background};
      border: 1px solid ${theme.colors.cardBorder};
      border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      height: auto;
    `}; */
`
const LabelRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /* flex-flow: row nowrap; */
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
  border-radius: 16px;
  padding: 0.7rem 0.7rem 0.8rem 0.7rem;
  background: #22cda64d;
  // box-shadow: ${({ theme, error }) => theme.shadows[error ? 'warning' : 'inset']};
  /* ${({ zapStyle }) =>
    !!zapStyle &&
    css`
      border-radius: 0px 16px 16px 16px;
    `}; */
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  showSearchInput?: boolean
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showUSDPrice?: boolean
  tokensToShow?: Token[]
}

const StyledInputWrapper = styled.div<InputProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.radii.card};
  border: 2px solid
    ${({ theme, isWarning, isSuccess }) => {
      if (isWarning) {
        return theme.shadows.warning
      }

      if (isSuccess) {
        return theme.shadows.success
      }
      return theme.colors.primary
    }};
`

const StyledNumericalInput = styled(NumericalInput)<InputProps>`
  width: 100%;
  text-align: left;
`
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  showSearchInput,
  disabled,
  error,
  showUSDPrice,
  tokensToShow,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()
  const theme = useTheme()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const amountInDollar = useBUSDCurrencyAmount(
    showUSDPrice ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      showSearchInput={showSearchInput}
      tokensToShow={tokensToShow}
    />,
  )

  const percentAmount = useMemo(
    () => ({
      25: maxAmount ? maxAmount.multiply(new Percent(25, 100)).toExact() : undefined,
      50: maxAmount ? maxAmount.multiply(new Percent(50, 100)).toExact() : undefined,
      75: maxAmount ? maxAmount.multiply(new Percent(75, 100)).toExact() : undefined,
    }),
    [maxAmount],
  )

  const [currentClickedPercent, setCurrentClickedPercent] = useState('')

  const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

  return (
    <Box position="relative" id={id}>
      <InputPanel>
        <Container as="label" zapStyle={zapStyle} error={error}>
          <Flex alignItems="center" mb={1}>
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
            ) : currency ? (
              <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
            ) : null}
            {pair ? (
              <Text id="pair" bold>
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair" bold>
                {(currency && currency.symbol && currency.symbol.length > 10
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length,
                    )} `
                  : currency?.symbol) || t('Select a currency')}
              </Text>
            )}
            {token && tokenAddress ? (
              <Flex style={{ gap: '4px' }} ml="8px" alignItems="center">
                <CopyButton
                  width="16px"
                  buttonColor="textSubtle"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                />
                <AddToWalletButton
                  variant="text"
                  p="0"
                  height="auto"
                  width="fit-content"
                  tokenAddress={tokenAddress}
                  tokenSymbol={token.symbol}
                  tokenDecimals={token.decimals}
                  tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                />
              </Flex>
            ) : null}
          </Flex>

          <LabelRow>
            <Flex alignItems="center" width="100%">
              {/* InputPanel */}
              <StyledInputWrapper>
                <Flex flexGrow={1}>
                  <StyledNumericalInput
                    error={error}
                    disabled={disabled}
                    className="token-amount-input"
                    value={value}
                    onBlur={onInputBlur}
                    onUserInput={(val) => {
                      onUserInput(val)
                      // setCurrentClickedPercent('')
                    }}
                  />
                  <InputRow selected={disableCurrencySelect}>
                    {account && currency && selectedCurrencyBalance?.greaterThan(0) && !disabled && label !== 'To' && (
                      <Flex alignItems="right" justifyContent="right">
                        {maxAmount?.greaterThan(0) && showMaxButton && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              onMax?.()

                              // setCurrentClickedPercent('MAX')
                            }}
                            scale="sm"
                            variant={isAtPercentMax ? 'primary' : 'secondary'}
                            style={{
                              textTransform: 'uppercase',
                              borderRadius: '25px',
                              padding: '0 8px',
                              height: '20px',
                            }}
                          >
                            {t('Max')}
                          </Button>
                        )}
                      </Flex>
                    )}
                  </InputRow>
                </Flex>
              </StyledInputWrapper>

              {/* TokenListModal */}
              <Button
                scale="md"
                ml={2}
                p={0}
                style={{
                  borderRadius: '25px',
                  background:
                    'linear-gradient(90deg, #0FD7D2 -0.04%, #17D8CA 3.63%, #39DFA7 22.68%, #54E48B 41.91%, #68E877 61.17%, #73EA6B 80.5%, #77EB67 100.01%)',
                  width: '100%',
                  maxWidth: '110px',
                }}
              >
                <Flex>
                  {beforeButton}
                  <CurrencySelectButton
                    zapStyle={zapStyle}
                    className="open-currency-select-button"
                    selected={!!currency}
                    onClick={() => {
                      if (!disableCurrencySelect) {
                        onPresentCurrencyModal()
                      }
                    }}
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      {pair ? (
                        <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                      ) : currency ? (
                        <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                      ) : null}
                      {pair ? (
                        <Text id="pair" bold color="#000">
                          {pair?.token0.symbol}:{pair?.token1.symbol}
                        </Text>
                      ) : (
                        <Text id="pair" bold color="#000">
                          {(currency && currency.symbol && currency.symbol.length > 10
                            ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                                currency.symbol.length - 5,
                                currency.symbol.length,
                              )} `
                            : currency?.symbol) || t('Select a currency')}
                        </Text>
                      )}
                      {
                        !disableCurrencySelect && (
                          <ArrowDropDownIcon width={25} height={30} color="background" className="down-icon" />
                        )

                        // <ChevronDownIcon />
                      }
                    </Flex>
                  </CurrencySelectButton>
                </Flex>
              </Button>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" width="100%" mt={1}>
              {currency && showUSDPrice ? (
                <Flex justifyContent="flex-end" mt={1} mr="1rem">
                  <Flex maxWidth="200px">
                    {Number.isFinite(amountInDollar) ? (
                      <Text fontSize="14px" color="white" style={{ whiteSpace: 'nowrap' }}>
                        ~ ${formatNumber(amountInDollar)} USD
                      </Text>
                    ) : (
                      <Box height="18px" />
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Text fontSize="13px" color="textSubtle">
                  $0
                </Text>
              )}
              {account && (
                <Text
                  onClick={!disabled && onMax}
                  color="whites"
                  fontSize="14px"
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency
                    ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                    : ' -'}
                </Text>
              )}
            </Flex>
          </LabelRow>
        </Container>
        {disabled && <Overlay />}
      </InputPanel>
    </Box>
  )
}
